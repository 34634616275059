<template lang="pug">
  .tabs-panel
    .tabs-panel__tabs
      .tabs-panel__tab-item(
        v-for="item in options"
        :key="item.id"
        :class="{ 'tabs-panel__tab-item--active': active === item.id }"
        @click="active = item.id")
        | {{ item.title }}

    .tabs-panel__content
      component(
        v-if="active !== null"
        :is="options[active].component")

</template>

<script>
import EmployeesList from './employees-list/EmployeesList'

export default {
  name: 'TabsPanel',

  components: {
    EmployeesList
  },

  data: (vm) => ({
    options: [
      { id: 0, title: vm.$t('ui.labels.employees'), component: 'EmployeesList' }
    ],
    active: 0
  })
}
</script>

<style lang="scss" scoped>

  .tabs-panel {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 100%;

    &__tabs {
      width: 100%;
      display: flex;
      background-color: $website-color;
    }

    &__tab-item {
      cursor: pointer;
      color: #616161;
      font-size: 18px;
      padding: 14px 40px;
      width: 100%;

      &--active {
        background-color: $color-white;
      }
    }

    &__content {
      height: 100%;
    }
  }
</style>
